<template>
  <el-form ref="form" :model="bookkeepingEntryForm" label-position="left" label-width="115px" :inline="true">
    <div class="field-row">
      <span class="field-lable">{{ $t('bookkeeping.exportWizard.fields.supplier') }}</span>
      <TruncatedText class="me-2 supplier-number"> {{ supplierName }}</TruncatedText>
    </div>
    <div class="field-row">
      <span class="field-lable">{{ $t('bookkeeping.exportWizard.fields.supplierNumber') }}</span>
      <span class="field-value">{{ supplierNumber }}</span>
    </div>
    <div class="el-form-field-row">
      <el-form-item :label="$t('bookkeeping.exportWizard.fields.codeAccount')" prop="code">
        <el-input v-model="bookkeepingEntryForm.code" size="small" @blur="handleUpdateCode" />
      </el-form-item>
      <el-tooltip
        :content="getTooltipContent(codeBookkeepingAccount, bookkeepingEntryForm.code)"
        effect="dark"
        :visible-arrow="false"
        placement="left"
        class="mt-2"
      >
        <span>
          <Button
            v-if="!bookkeepingAccountsLoading && bookkeepingEntryForm.code"
            :disabled="codeBookkeepingAccount && codeBookkeepingAccount.account === bookkeepingEntryForm.code"
            type="icon"
            class="save-default-button"
            @click="() => handleSaveDefault(ACCOUNT_TYPES.CODE, bookkeepingEntryForm.code)"
          >
            <SaveIcon v-if="!codeBookkeepingAccount" width="16" height="16" />
            <div
              v-else-if="codeBookkeepingAccount.account === bookkeepingEntryForm.code"
              class="position-relative d-flex align-self-center"
            >
              <SaveIcon width="16" height="16" />
              <CheckCircleFullIcon
                class="text-success position-absolute translate-middle badge-position"
                width="16"
                height="16"
              />
            </div>
            <RefreshIcon v-else width="16" height="16" />
          </Button>
        </span>
      </el-tooltip>
    </div>
    <div class="field-row">
      <span class="field-lable">{{ $t('bookkeeping.exportWizard.fields.reference') }}</span>
      <span class="field-value">{{ bookkeepingEntryForm.reference }}</span>
    </div>
    <div class="field-row">
      <span class="field-lable">{{ $t('bookkeeping.exportWizard.fields.allocationNumber') }}</span>
      <span class="field-value">{{ bookkeepingEntryForm.allocationNumber }}</span>
    </div>
    <div class="field-row">
      <span class="field-lable">{{ $t('bookkeeping.exportWizard.fields.date') }}</span>
      <span class="field-value">{{ formatDate(bookkeepingEntryForm.referenceDate) }}</span>
    </div>
    <div class="el-form-field-row align-items-start">
      <el-form-item :label="$t('bookkeeping.exportWizard.fields.valueDate')" prop="valueDate">
        <el-date-picker
          v-model="bookkeepingEntryForm.valueDate"
          :format="$direction === 'ltr' ? 'MM/dd/yyyy' : 'dd.MM.yyyy'"
          size="small"
          type="date"
          @change="handleUpdateValueDate"
        >
        </el-date-picker>
      </el-form-item>
    </div>
    <div class="el-form-field-row">
      <el-form-item
        :label="$t('bookkeeping.exportWizard.fields.debitAccount')"
        :rules="{ required: true }"
        prop="debitAccount1"
      >
        <template slot="error">
          <small class="el-form-item__error">
            {{ $t('bookkeeping.exportWizard.validations.requiredField') }}
          </small>
        </template>
        <el-input v-model="bookkeepingEntryForm.debitAccount1" size="small" @blur="handleUpdateDebits" />
      </el-form-item>
      <el-tooltip
        :content="getTooltipContent(debitBookkeepingAccount, bookkeepingEntryForm.debitAccount1)"
        effect="dark"
        :visible-arrow="false"
        placement="left"
        class="mt-2"
      >
        <span>
          <Button
            v-if="!bookkeepingAccountsLoading && bookkeepingEntryForm.debitAccount1"
            :disabled="
              debitBookkeepingAccount && debitBookkeepingAccount.account === bookkeepingEntryForm.debitAccount1
            "
            type="icon"
            class="save-default-button"
            @click="() => handleSaveDefault(ACCOUNT_TYPES.DEBIT, bookkeepingEntryForm.debitAccount1)"
          >
            <SaveIcon v-if="!debitBookkeepingAccount" width="16" height="16" />
            <div
              v-else-if="debitBookkeepingAccount.account === bookkeepingEntryForm.debitAccount1"
              class="position-relative d-flex align-self-center"
            >
              <SaveIcon width="16" height="16" />
              <CheckCircleFullIcon
                class="text-success position-absolute translate-middle badge-position"
                width="16"
                height="16"
              />
            </div>
            <RefreshIcon v-else width="16" height="16" />
          </Button>
        </span>
      </el-tooltip>
    </div>
    <div class="el-form-field-row">
      <el-form-item
        :required="isDebit2Required"
        :label="$t('bookkeeping.exportWizard.fields.vatAccount')"
        prop="debitAccount2"
      >
        <template slot="error">
          <small class="el-form-item__error">
            {{ $t('bookkeeping.exportWizard.validations.requiredField') }}
          </small>
        </template>
        <el-input
          v-model="bookkeepingEntryForm.debitAccount2"
          :disabled="!isDebit2Required"
          size="small"
          @blur="handleUpdateDebits"
        />
      </el-form-item>
      <el-tooltip
        :content="getTooltipContent(vatBookkeepingAccount, bookkeepingEntryForm.debitAccount2)"
        effect="dark"
        :visible-arrow="false"
        placement="left"
        class="mt-2"
      >
        <span>
          <Button
            v-if="!bookkeepingAccountsLoading && bookkeepingEntryForm.debitAccount2"
            :disabled="vatBookkeepingAccount && vatBookkeepingAccount.account === bookkeepingEntryForm.debitAccount2"
            class="save-default-button"
            type="icon"
            @click="() => handleSaveDefault(ACCOUNT_TYPES.VAT, bookkeepingEntryForm.debitAccount2)"
          >
            <SaveIcon v-if="!vatBookkeepingAccount" width="16" height="16" />
            <div
              v-else-if="vatBookkeepingAccount.account === bookkeepingEntryForm.debitAccount2"
              class="position-relative d-flex align-self-center"
            >
              <SaveIcon width="16" height="16" />
              <CheckCircleFullIcon
                class="text-success position-absolute translate-middle badge-position"
                width="16"
                height="16"
              />
            </div>
            <RefreshIcon v-else width="16" height="16" />
          </Button>
        </span>
      </el-tooltip>
    </div>
    <div class="el-form-field-row">
      <el-form-item
        :label="$t('bookkeeping.exportWizard.fields.creditAccount')"
        :rules="{ required: true }"
        prop="creditAccount"
      >
        <template slot="error">
          <small class="el-form-item__error">
            {{ $t('bookkeeping.exportWizard.validations.requiredField') }}
          </small>
        </template>
        <el-input v-model="bookkeepingEntryForm.creditAccount" size="small" @blur="handleUpdateCredits" />
      </el-form-item>
      <el-tooltip
        :content="getTooltipContent(creditBookkeepingAccount, bookkeepingEntryForm.creditAccount)"
        effect="dark"
        :visible-arrow="false"
        placement="left"
        class="mt-2"
      >
        <span>
          <Button
            v-if="!bookkeepingAccountsLoading && bookkeepingEntryForm.creditAccount"
            :disabled="
              creditBookkeepingAccount && creditBookkeepingAccount.account === bookkeepingEntryForm.creditAccount
            "
            class="save-default-button"
            type="icon"
            @click="() => handleSaveDefault(ACCOUNT_TYPES.CREDIT, bookkeepingEntryForm.creditAccount)"
          >
            <SaveIcon v-if="!creditBookkeepingAccount" width="16" height="16" />
            <div
              v-else-if="creditBookkeepingAccount.account === bookkeepingEntryForm.creditAccount"
              class="position-relative d-flex align-self-center"
            >
              <SaveIcon width="16" height="16" />
              <CheckCircleFullIcon
                class="text-success position-absolute translate-middle badge-position"
                width="16"
                height="16"
              />
            </div>
            <RefreshIcon v-else width="16" height="16" />
          </Button>
        </span>
      </el-tooltip>
    </div>
    <div class="field-row">
      <span class="field-lable">{{ $t('bookkeeping.exportWizard.fields.debitAmount') }}</span>
      <span class="field-value">{{ formatToCurrency(bookkeepingEntryForm.debitAmount1) ?? '-' }}</span>
    </div>
    <div class="field-row">
      <span class="field-lable">{{ $t('bookkeeping.exportWizard.fields.vatAmount') }}</span>
      <span class="field-value">{{ formatToCurrency(bookkeepingEntryForm.debitAmount2) ?? '-' }}</span>
    </div>
    <div class="field-row">
      <span class="field-lable">{{ $t('bookkeeping.exportWizard.fields.creditAmount') }}</span>
      <span class="field-value">{{ formatToCurrency(bookkeepingEntryForm.creditAmount) ?? '-' }}</span>
    </div>
    <div class="el-form-field-row">
      <el-form-item :label="$t('bookkeeping.exportWizard.fields.details')" prop="details">
        <el-input v-model="bookkeepingEntryForm.details" size="small" @blur="handleUpdateDetails" />
      </el-form-item>
    </div>
  </el-form>
</template>

<script>
import { ref, computed } from 'vue';

import { options } from '@/locale/dateConfig';
import { TruncatedText, Button } from '@/modules/core';
import { SaveIcon, RefreshIcon, CheckCircleFullIcon } from '@/assets/icons';
import { useCurrency } from '@/modules/core/compositions/money-currency';

import { ACCOUNT_TYPES } from '../../constants';
import { DateTime } from 'luxon';

export default {
  name: 'BookkeepingEntryForm',
  components: { Button, TruncatedText, SaveIcon, RefreshIcon, CheckCircleFullIcon },
  props: {
    billingEntry: { type: Object, required: true },
    billing: { type: Object, required: true },
    bookkeepingAccountsLoading: { type: Boolean, default: true },
    debitBookkeepingAccount: { type: Object, default: null },
    vatBookkeepingAccount: { type: Object, default: null },
    creditBookkeepingAccount: { type: Object, default: null },
    codeBookkeepingAccount: { type: Object, default: null },
  },
  setup(props) {
    const bookkeepingEntryForm = ref({
      code: props.billingEntry.bookkeepingEntry.code,
      reference: props.billingEntry.bookkeepingEntry.reference,
      allocationNumber: props.billingEntry.bookkeepingEntry.allocationNumber,
      referenceDate: props.billingEntry.bookkeepingEntry.referenceDate,
      valueDate: props.billingEntry.bookkeepingEntry.valueDate,
      details: props.billingEntry.bookkeepingEntry.details,
      debitAccount1: props.billingEntry.bookkeepingEntry.debits[0].account,
      debitAccount2: props.billingEntry.bookkeepingEntry.debits[1]?.account,
      creditAccount: props.billingEntry.bookkeepingEntry.credits[0].account,
      debitAmount1: props.billingEntry.bookkeepingEntry.debits[0].amount,
      debitAmount2: props.billingEntry.bookkeepingEntry.debits[1]?.amount,
      creditAmount: props.billingEntry.bookkeepingEntry.credits[0].amount,
    });
    const { formatToCurrency } = useCurrency();

    const isDebit2Required = ref(!!props.billingEntry.bookkeepingEntry?.debits?.[1].amount);

    return {
      formatToCurrency,
      bookkeepingEntryForm,
      supplierName: computed(() => props.billing.supplier.name),
      supplierId: computed(() => props.billing.supplier.id),
      supplierNumber: computed(() => props.billing.supplier.number),
      isDebit2Required,
      ACCOUNT_TYPES,
    };
  },
  watch: {
    billingEntry(newDoc, previousDoc) {
      if (!newDoc) return;
      if (newDoc.id === previousDoc.id) return;
      this.bookkeepingEntryForm.code = newDoc.bookkeepingEntry.code;
      this.bookkeepingEntryForm.reference = newDoc.bookkeepingEntry.reference;
      this.bookkeepingEntryForm.allocationNumber = newDoc.bookkeepingEntry.allocationNumber;
      this.bookkeepingEntryForm.referenceDate = newDoc.bookkeepingEntry.referenceDate;
      this.bookkeepingEntryForm.valueDate = newDoc.bookkeepingEntry.valueDate;
      this.bookkeepingEntryForm.details = newDoc.bookkeepingEntry.details;
      this.bookkeepingEntryForm.debitAccount1 = newDoc.bookkeepingEntry.debits[0].account;
      this.bookkeepingEntryForm.debitAccount2 = newDoc.bookkeepingEntry.debits[1].account;
      this.bookkeepingEntryForm.creditAccount = newDoc.bookkeepingEntry.credits[0].account;
      this.bookkeepingEntryForm.debitAmount1 = newDoc.bookkeepingEntry.debits[0].amount;
      this.bookkeepingEntryForm.debitAmount2 = newDoc.bookkeepingEntry.debits[1].amount;
      this.bookkeepingEntryForm.creditAmount = newDoc.bookkeepingEntry.credits[0].amount;
      this.isDebit2Required = !!newDoc.bookkeepingEntry.debits[1].amount;
    },
  },
  methods: {
    formatDate(date) {
      return date ? new Date(date).toLocaleDateString(this.$i18n.locale, options.short) : '-';
    },
    getTooltipContent(currentDefault, currentFormValue) {
      if (!currentDefault) return this.$t('bookkeeping.exportWizard.tooltips.saveAsDefault');
      if (currentDefault.account === currentFormValue)
        return this.$t('bookkeeping.exportWizard.tooltips.defaultValue', { no: currentFormValue });
      else
        return this.$t('bookkeeping.exportWizard.tooltips.updateDefaultValue', {
          from: currentDefault.account,
          to: currentFormValue,
        });
    },
    handleSaveDefault(type, number) {
      this.$emit('save-default', { type, number });
    },
    handleUpdateCredits() {
      const { creditAccount, creditAmount } = this.bookkeepingEntryForm;
      const data = {
        bookkeepingEntry: {
          credits: [{ account: creditAccount ? creditAccount : null, amount: creditAmount }],
        },
      };
      this.$emit('update-billing-entry', data);
    },
    handleUpdateDebits() {
      const { debitAccount1, debitAccount2, debitAmount1, debitAmount2 } = this.bookkeepingEntryForm;
      const data = {
        bookkeepingEntry: {
          debits: [
            { account: debitAccount1 ? debitAccount1 : null, amount: debitAmount1 },
            { account: debitAccount2 ? debitAccount2 : null, amount: debitAmount2 },
          ],
        },
      };
      this.$emit('update-billing-entry', data);
    },
    handleUpdateCode() {
      const { code } = this.bookkeepingEntryForm;
      const data = {
        bookkeepingEntry: {
          code: code ? code : null,
        },
      };
      this.$emit('update-billing-entry', data);
    },
    handleUpdateValueDate() {
      const { valueDate } = this.bookkeepingEntryForm;
      const data = {
        bookkeepingEntry: {
          valueDate: valueDate ? DateTime.fromJSDate(valueDate).toISODate() : null,
        },
      };
      this.$emit('update-billing-entry', data);
    },
    handleUpdateDetails() {
      const { details } = this.bookkeepingEntryForm;
      const data = {
        bookkeepingEntry: {
          details: details ? details : null,
        },
      };
      this.$emit('update-billing-entry', data);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

.field-row {
  display: flex;
  height: 32px;
  margin-top: 4px;
  align-items: center;
}

.field-lable {
  min-width: 115px;
  width: 115px;
  color: $typography-secondary;
  display: inline-block;
  font-size: 12px;
}

.field-value {
  margin-right: 10px;
}

.el-form-field-row {
  display: flex;
  min-height: 32px;
}

:deep(.el-form-item) {
  margin: 4px 0 0 0;
  height: 32px;

  &.is-error {
    height: 50px;
  }

  .el-form-item__content {
    line-height: 32px;
  }
}

:deep(.el-form-item .el-form-item__label) {
  text-align: start;
  color: $typography-secondary !important;
  height: 32px;
  line-height: 32px;
  font-size: 12px;
}

:deep(.el-input) {
  text-align: start;
  width: 136px;
  height: 32px;
  line-height: 32px;

  .el-input__inner {
    padding: 10px;
  }
}

.badge-position {
  top: 15%;
  background: white;
  border-radius: 12px;
}

.badge-position {
  [dir='ltr'] & {
    left: 90%;
  }
  [dir='rtl'] & {
    left: 10%;
  }
}

.supplier-number {
  width: 150px;
}

.save-default-button {
  [dir='rtl'] & {
    margin-right: 0.25rem;
  }
  [dir='ltr'] & {
    margin-left: 0.25rem;
  }
}
</style>
