<template>
  <div>
    <WizardLayout
      :loading="loading"
      :entry-id="currentBillingEntry.id"
      @close="$emit('close')"
      @entry-update="(entryId) => $emit('billing-entries-update', [entryId])"
    >
      <template #title>
        <div>
          <h2>
            {{ title }}
          </h2>
          <p>{{ subTitle }}</p>
        </div>
      </template>
      <template #form>
        <BookkeepingEntryForm
          ref="form"
          :billing-entry="currentBillingEntry"
          :billing="currentBilling"
          :bookkeeping-accounts-loading="bookkeepingAccountsLoading"
          :debit-bookkeeping-account="debitBookkeepingAccount"
          :vat-bookkeeping-account="vatBookkeepingAccount"
          :credit-bookkeeping-account="creditBookkeepingAccount"
          :code-bookkeeping-account="codeBookkeepingAccount"
          @update-billing-entry="handleBillingEntryUpdate"
          @save-default="handleSaveDefault"
        />
      </template>
      <template #document-preview>
        <DocumentPreview :document="currentDocument" />
      </template>
      <template #footer>
        <div class="d-flex justify-content-end">
          <div class="d-flex">
            <Button
              :class="$direction === 'rtl' ? 'ms-2' : 'me-2'"
              :disabled="loading || currentIndex === 0"
              type="secondary"
              @click="handlePreviousClick"
            >
              {{ $t('bookkeeping.exportWizard.buttons.previous') }}
            </Button>
            <Button :disabled="loading || currentIndex + 1 >= allBillingEntries.length" @click="goToNext">
              {{ $t('bookkeeping.exportWizard.buttons.next') }}
            </Button>
          </div>
        </div>
      </template>
    </WizardLayout>
    <ConfirmSaveDefaultAccount
      v-if="openPopup === 'showConfirmSaveDefaultAccount'"
      :type="accountToUpdateType"
      @confirm="handleSaveDefaultAccountConfirmed"
      @close="openPopup = undefined"
    />
  </div>
</template>

<script>
import { computed, ref, watch } from 'vue';
import { isNil } from 'ramda';

import { Button } from '@/modules/core';

import WizardLayout from './WizardLayout';
import DocumentPreview from './DocumentPreview.vue';
import BookkeepingEntryForm from './BookkeepingEntryForm.vue';
import { useExportWizardBillingEntries } from '../composition/useExportWizardBillingEntries';
import { useSupplierBookkeepingAccounts } from '../composition/useSupplierBookkeepingAccounts';
import ConfirmSaveDefaultAccount from './ConfirmSaveDefaultAccount';
import { ACCOUNT_TYPES } from '../../constants';

export default {
  name: 'BillingEntriesScouting',
  components: {
    WizardLayout,
    Button,
    DocumentPreview,
    BookkeepingEntryForm,
    ConfirmSaveDefaultAccount,
  },
  props: {
    billings: { type: Array, required: true },
    allBillingEntries: { type: Array, required: true },
    initialIndex: { type: Number, required: true },
    customer: { type: Object, required: true },
    bookkeeperId: { type: String, required: true },
  },
  setup(props, { emit }) {
    const currentIndex = ref(props.initialIndex);
    const currentDocument = ref(null);

    const {
      ongoingBillingEntries,
      validOngoingBillingEntries,
      invalidOngoingBillingEntries,
      updateBillingEntry,
      bulkUpdateBillingEntries,
      bulkUpdateBillingEntriesOnDone,
    } = useExportWizardBillingEntries(
      computed(() => ({
        billingEntries: props.allBillingEntries,
        ongoingBillingIds: props.allBillingEntries.map(({ billingId }) => billingId),
        billings: props.billings,
      }))
    );

    bulkUpdateBillingEntriesOnDone(() => emit('billing-entries-update'));

    const currentBillingEntry = computed(() => ongoingBillingEntries.value[currentIndex.value]);
    const currentBilling = computed(() => props.billings?.find(({ id }) => id === currentBillingEntry.value.billingId));
    const currentSupplierId = computed(() => currentBilling.value.supplier.id);

    watch(
      () => ongoingBillingEntries.value.length,
      (entriesLength) => {
        if (entriesLength <= 0) {
          emit('close');
        }

        if (entriesLength === currentIndex.value) {
          currentIndex.value = entriesLength - 1;
        }
      }
    );

    watch(
      () => currentBilling.value.id,
      (newId) => {
        if (!isNil(newId)) {
          currentDocument.value = currentBilling.value?.eventReferences?.find(
            (eventReference) => eventReference.regulatory
          ).document;
        }
      },
      { immediate: true }
    );

    const {
      debitBookkeepingAccount,
      creditBookkeepingAccount,
      vatBookkeepingAccount,
      codeBookkeepingAccount,
      loading: bookkeepingAccountsLoading,
      createOrUpdateBookkeepingAccount,
    } = useSupplierBookkeepingAccounts(
      computed(() => ({
        bookkeeperId: props.bookkeeperId,
        customerId: props.customer.id,
        supplierId: currentSupplierId.value,
      }))
    );

    return {
      ongoingBillingEntries,
      validOngoingBillingEntries,
      invalidOngoingBillingEntries,
      loading: ref(false),
      currentIndex,
      currentBillingEntry,
      currentBilling,
      currentSupplierId,
      currentDocument,
      bookkeepingAccountsLoading,
      debitBookkeepingAccount,
      creditBookkeepingAccount,
      vatBookkeepingAccount,
      codeBookkeepingAccount,
      updateBillingEntry,
      bulkUpdateBillingEntries,
      openPopup: ref(undefined),
      createOrUpdateBookkeepingAccount,
      accountToUpdateType: ref(null),
      accountToUpdateNumber: ref(null),
    };
  },
  computed: {
    title() {
      return this.$t('bookkeeping.exportWizard.title', {
        index: this.currentIndex + 1,
        total: this.allBillingEntries.length,
      });
    },
    subTitle() {
      return this.customer?.name;
    },
  },
  methods: {
    async goToNext() {
      this.$refs.form.$refs.form.resetFields();
      this.currentIndex += 1;
      await this.$nextTick();
      await this.$nextTick();
      this.$refs.form.$refs.form.clearValidate();
    },
    async handlePreviousClick() {
      this.$refs.form.$refs.form.resetFields();
      this.currentIndex -= 1;
      await this.$nextTick();
      await this.$nextTick();
      this.$refs.form.$refs.form.clearValidate();
    },
    handleBillingEntryUpdate(updateData) {
      this.updateBillingEntry({ id: this.currentBillingEntry.id, data: updateData });
    },
    getCurrentAccount(type) {
      switch (type) {
        case ACCOUNT_TYPES.DEBIT:
          return this.debitBookkeepingAccount;
        case ACCOUNT_TYPES.VAT:
          return this.vatBookkeepingAccount;
        case ACCOUNT_TYPES.CREDIT:
          return this.creditBookkeepingAccount;
        case ACCOUNT_TYPES.CODE:
          return this.codeBookkeepingAccount;
        default:
          return;
      }
    },
    async handleSaveDefaultAccountConfirmed({ override }) {
      this.loading = true;
      this.openPopup = undefined;
      const currentAccount = this.getCurrentAccount(this.accountToUpdateType);
      await this.createOrUpdateBookkeepingAccount(
        currentAccount,
        this.accountToUpdateType,
        this.accountToUpdateNumber,
        this.bookkeeperId
      );
      await this.bulkUpdateBillingEntries({
        data: {
          customerId: this.customer.id,
          ...(this.accountToUpdateType !== ACCOUNT_TYPES.VAT && { supplierId: this.currentSupplierId }),
          accountType: this.accountToUpdateType,
          accountNumber: this.accountToUpdateNumber,
          override,
        },
      });
      this.accountToUpdateType = null;
      this.accountToUpdateNumber = null;
      this.loading = false;
    },
    handleSaveDefault({ type, number }) {
      this.accountToUpdateType = type;
      this.accountToUpdateNumber = number;
      this.openPopup = 'showConfirmSaveDefaultAccount';
    },
  },
};
</script>
