<template>
  <PopupLayout width="26rem" @close="$emit('close')">
    <template #title>
      <h2 v-if="action === 'delete'" class="title">
        {{ $t('bookkeeping.exportWizard.dialogs.saveAsDefault.titleDelete') }}
      </h2>
      <h2 v-else class="title">{{ $t('bookkeeping.exportWizard.dialogs.saveAsDefault.title') }}</h2>
    </template>
    <template #body>
      <div class="mb-4">
        <p class="word-break">
          <span class="fw-bold">{{ $t('bookkeeping.exportWizard.dialogs.saveAsDefault.noOverride') }}</span> -
          {{ $tc('bookkeeping.exportWizard.dialogs.saveAsDefault.noOverrideExplanation', typesCount) }}
        </p>
        <p class="word-break">
          {{
            $tc('bookkeeping.exportWizard.dialogs.saveAsDefault.noOverrideExplanation2', typesCount, { accountType })
          }}
        </p>
      </div>
      <div>
        <p class="word-break">
          <span class="fw-bold">{{ $t('bookkeeping.exportWizard.dialogs.saveAsDefault.override') }}</span> -
          {{ $tc('bookkeeping.exportWizard.dialogs.saveAsDefault.overrideExplanation', typesCount) }}
        </p>
        <p class="word-break">
          {{ $tc('bookkeeping.exportWizard.dialogs.saveAsDefault.overrideExplanation2', typesCount, { accountType }) }}
        </p>
      </div>
    </template>
    <template #buttons>
      <div>
        <Button type="secondary" @click="$emit('confirm', { override: false })">
          {{ $t('bookkeeping.exportWizard.dialogs.saveAsDefault.noOverride') }}
        </Button>
        <Button type="secondary" @click="$emit('confirm', { override: true })">
          {{ $t('bookkeeping.exportWizard.dialogs.saveAsDefault.override') }}
        </Button>
      </div>
    </template>
  </PopupLayout>
</template>

<script>
import { computed, getCurrentInstance } from 'vue';

import { Button } from '@/modules/core';

import PopupLayout from '../../components/PopupLayout';

export default {
  components: { PopupLayout, Button },
  props: {
    type: { type: [String, Array], required: true },
    action: { type: String, required: false, default: null },
  },
  setup(props) {
    const root = getCurrentInstance().proxy;
    return {
      typesCount: Array.isArray(props.type) ? props.type.length : 1,
      accountType: computed(() => {
        if (Array.isArray(props.type)) {
          return props.type.map((type) => root.$t(`bookkeeping.exportWizard.fields.${type}Account`)).join('״ ו-״');
        }
        return root.$t(`bookkeeping.exportWizard.fields.${props.type}Account`);
      }),
    };
  },
};
</script>

<style scoped lang="scss">
.title {
  font-size: 1.25rem;
}

.word-break {
  word-break: normal;
}
</style>
