<template>
  <div class="document-preview d-flex flex-column w-100 h-100">
    <Tabs
      class="flex-1"
      :tabs="[{ text: $t('document.modal.tab.file') }, { text: $t('document.modal.tab.digital') }]"
      :active-tab.sync="activeTab"
      start-space="1rem"
    />
    <div class="document-preview-tab-content h-100 ps-4 pe-4">
      <div v-if="activeTab === 0" class="h-100 pt-4">
        <FileViewer v-if="document" :url="document.filePathUrl" />
        <div v-else>
          <h3>{{ $t('document.modal.documentFile.failedToLoad') }}</h3>
          <p>{{ $t('document.modal.documentFile.missingData') }}</p>
        </div>
      </div>
      <DigitalDocument v-if="activeTab === 1" v-loading="documentLoading" :document="fullDocument" class="pt-4" />
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue';

import { Tabs } from '@/modules/core';
import { FileViewer } from '@clarityo/ui-components';
import { useDocumentNew2 } from '@/modules/document/compositions';

import DigitalDocument from '../../../documentModal/DigitalDocument';

export default {
  name: 'DocumentPreview',
  components: { Tabs, FileViewer, DigitalDocument },
  props: {
    document: { type: Object, default: null },
  },
  setup(props) {
    const { document: fullDocument, loading: documentLoading } = useDocumentNew2(computed(() => props.document?.id));
    return { activeTab: ref(0), fullDocument, documentLoading };
  },
};
</script>

<style lang="scss" scoped>
:deep(.nav-tabs) {
  & a {
    padding-top: 0.5rem;
  }
}
</style>
